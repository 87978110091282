// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CtaButton {
  display: flex;
  width: 70%;
  height: 2.4rem;
  align-items: center;
  justify-content: center;
  margin: auto;
  font-size: 1rem;
  font-weight: 200;
  border-radius: 5px;
  color: #5C5C5C;
  background-color: white;
}
.CtaButton--primary {
  color: white;
  background-color: #0A94D5;
  font-size: 1.3rem;
  width: 100%;
  height: 3.2rem;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/CtaButton.scss","webpack://./src/assets/styles/global-vars.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,UAAA;EACA,cAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EAEA,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,cCRO;EDSP,uBCXM;ADSR;AAIE;EACE,YCdI;EDeJ,yBClBS;EDmBT,iBAAA;EACA,WAAA;EACA,cAAA;AAFJ","sourcesContent":["@import \"./global-vars.scss\";\n\n.CtaButton {\n  display: flex;\n  width: 70%;\n  height: 2.4rem;\n  align-items: center;\n  justify-content: center;\n  margin: auto;\n\n  font-size: 1rem;\n  font-weight: 200;\n  border-radius: 5px;\n  color: $gray_1;\n  background-color: $white;\n\n  &--primary {\n    color: $white;\n    background-color: $main_color;\n    font-size: 1.3rem;\n    width: 100%;\n    height: 3.2rem;\n  }\n}\n","$main_color: #0A94D5;\n$secondary_color: #D3B112;\n\n$white: white;\n\n$gray_1: #5C5C5C;\n$gray_2: rgb(235, 235, 235);\n$gray_3: rgb(219, 219, 219);\n\n$section_v_padding: 0rem;\n$section_h_padding: 2.4rem;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
