import React, { useState } from "react";
import Section from "./Section";
import ReactPlayer from "react-player";

import "../styles/VideoSection.scss";

export default function VideoSection() {
  const [showingVideo, setShowingVideo] = useState(false);


  function showVideo() {
    setShowingVideo(true);
  }

  function hideVideo() {
    setShowingVideo(false);
  }

  return (
    <Section
      titleStyle={{ fontSize: "1.9rem", marginBottom: "1.4rem" }}
      title="Tan bonito que cuesta de imaginar&#13;¿verdad?"
    >
      <p className="VideoSection__p">¡Pues no imagines más!</p>
      <p className="VideoSection__p">
        Aquí tienes un vídeo para ver cómo funciona.
      </p>

      <div
        className={`VideoSection__video-wrapper ${showingVideo ? "VideoSection__video-wrapper--showing" : ""}`}
        data-umami-event="video-play"
      >
        <ReactPlayer
          width="100%"
          height="100%"
          url="https://www.youtube-nocookie.com/embed/-NfoZh-TjB8"
          onPlay={showVideo}
          onPause={hideVideo}
          onEnded={hideVideo}
        />
      </div>
    </Section>
  );
}
