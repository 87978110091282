// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SectionDivisor {
  position: relative;
  padding: 2.8rem 1rem;
  padding-bottom: 4.5rem;
  margin-top: 3rem;
  margin-bottom: 5rem;
  min-height: 20svh;
  min-height: 20vh;
  clip-path: polygon(0 0, 100% 7%, 100% 100%, 0 93%);
}
.SectionDivisor__text {
  font-size: 1.8rem;
  font-weight: 100;
  color: white;
  line-height: 1.4em;
  margin-bottom: 4rem;
}
.SectionDivisor__bg-pic {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  filter: brightness(0.25);
  object-fit: cover;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/SectionDivisor.scss","webpack://./src/assets/styles/global-vars.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,oBAAA;EACA,sBAAA;EAEA,gBAAA;EACA,mBAAA;EAEA,iBAAA;EACA,gBAAA;EAEA,kDAAA;AAJF;AAME;EACE,iBAAA;EACA,gBAAA;EACA,YCfI;EDgBJ,kBAAA;EACA,mBAAA;AAJJ;AAOE;EACE,WAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;EACA,YAAA;EACA,WAAA;EACA,WAAA;EACA,wBAAA;EACA,iBAAA;AALJ","sourcesContent":["@import \"../styles/global-vars.scss\";\n\n.SectionDivisor {\n  position: relative;\n  padding: 2.8rem 1rem;\n  padding-bottom: 4.5rem;\n\n  margin-top: 3rem;\n  margin-bottom: 5rem;\n\n  min-height: 20svh;\n  min-height: 20vh;\n\n  clip-path: polygon(0 0, 100% 7%, 100% 100%, 0 93%);\n\n  &__text {\n    font-size: 1.8rem;\n    font-weight: 100;\n    color: $white;\n    line-height: 1.4em;\n    margin-bottom: 4rem;\n  }\n\n  &__bg-pic {\n    content: \"\";\n    position: absolute;\n    top: 0;\n    left: 0;\n    height: 100%;\n    width: 100%;\n    z-index: -1;\n    filter: brightness(.25);\n    object-fit: cover;\n  }\n}\n","$main_color: #0A94D5;\n$secondary_color: #D3B112;\n\n$white: white;\n\n$gray_1: #5C5C5C;\n$gray_2: rgb(235, 235, 235);\n$gray_3: rgb(219, 219, 219);\n\n$section_v_padding: 0rem;\n$section_h_padding: 2.4rem;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
