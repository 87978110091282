import React from "react";
import Section from "./Section";

import "../styles/FeaturesSection.scss";

export default function FeaturesSection(props) {
  return (
    <Section {...props}>
      <div className="FeaturesSection__feature">
        <h3 className="FeaturesSection__subtitle">Gratis</h3>
        <p className="FeaturesSection__p">
          Como lo oyes. ¿A qué esperas entonces para unirte a Mitali?
        </p>
      </div>

      <div className="FeaturesSection__feature">
        <h3 className="FeaturesSection__subtitle">
          Ideal para runners y triatletas
        </h3>
        <p className="FeaturesSection__p">
          También funciona para ciclismo y natación, pero todavía estamos
          trabajando para tener todas las clasificaciones.
        </p>
      </div>

      <div className="FeaturesSection__feature">
        <h3 className="FeaturesSection__subtitle">Automático (beta)</h3>
        <p className="FeaturesSection__p">
          Crea tu cuenta y aparecerán mágicamente tus trofeos y medallas en el
          perfil.
          <br />
          Es más, ¡lo tendrás siempre actualizado!
          <br />
          <br />
          Esta función todavía está en fase beta, por lo que es posible que nos
          dejemos algún logro. Siempre podrás reclamar algún trofeo que no esté
          en tu perfil.
        </p>
      </div>

      <div className="FeaturesSection__feature">
        <h3 className="FeaturesSection__subtitle">Innovador</h3>
        <p className="FeaturesSection__p">
          Estamos seguros de que no habías visto nada igual. ¿Listo/a para
          tener tu estantería virtual?
        </p>
      </div>
    </Section>
  );
}
