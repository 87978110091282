// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Trophy {
  position: absolute;
  width: 7rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Trophy__pic {
  display: block;
  filter: drop-shadow(0 0 15px rgba(0, 0, 0, 0.6));
}
.Trophy__name {
  opacity: 0.8;
  font-size: 0.65rem;
  margin-top: 0.3rem;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/Trophy.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;AACF;AACE;EACE,cAAA;EACA,gDAAA;AACJ;AAEE;EACE,YAAA;EACA,kBAAA;EACA,kBAAA;AAAJ","sourcesContent":[".Trophy {\n  position: absolute;\n  width: 7rem;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n\n  &__pic {\n    display: block;\n    filter: drop-shadow(0 0 15px rgba(0, 0, 0, .6));\n  }\n\n  &__name {\n    opacity: .8;\n    font-size: .65rem;\n    margin-top: .3rem;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
