// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Section {
  margin: 0rem 2.4rem;
  position: relative;
  margin-bottom: 5rem;
}
.Section__title {
  font-weight: 100;
  font-size: 2.55rem;
  text-align: left;
  margin-bottom: 3rem;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/Section.scss"],"names":[],"mappings":"AAEA;EACE,mBAAA;EACA,kBAAA;EACA,mBAAA;AADF;AAGE;EACE,gBAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AADJ","sourcesContent":["@import \"./global-vars.scss\";\n\n.Section {\n  margin: $section_v_padding $section_h_padding;\n  position: relative;\n  margin-bottom: 5rem;\n\n  &__title {\n    font-weight: 100;\n    font-size: 2.55rem;\n    text-align: left;\n    margin-bottom: 3rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
