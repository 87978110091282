// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.szh-accordion {
  text-align: left;
}

.szh-accordion__item {
  border-bottom: 1px solid #0A94D5;
}

.szh-accordion__item-btn {
  background-color: transparent;
  border: none;
  padding: 0.8rem;
  text-align: left;
  width: 100%;
  font-size: 0.97rem;
}

.accordion-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chevron {
  margin-left: auto;
  transition: transform 0.25s cubic-bezier(0, 0, 0, 1);
}

.itemBtnExpanded .chevron {
  transform: rotate(180deg);
}

.szh-accordion__item-content {
  padding: 0.5rem;
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
  line-height: 1.1rem;
  opacity: 0.7;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/FAQSection.scss"],"names":[],"mappings":"AAEA;EACE,gBAAA;AADF;;AAIA;EACE,gCAAA;AADF;;AAIA;EACE,6BAAA;EACA,YAAA;EACA,eAAA;EACA,gBAAA;EACA,WAAA;EACA,kBAAA;AADF;;AAIA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AADF;;AAIA;EACE,iBAAA;EACA,oDAAA;AADF;;AAIA;EACE,yBAAA;AADF;;AAIA;EACE,eAAA;EACA,iBAAA;EACA,qBAAA;EACA,mBAAA;EACA,YAAA;AADF","sourcesContent":["@import \"./global-vars.scss\";\n\n.szh-accordion {\n  text-align: left;\n}\n\n.szh-accordion__item {\n  border-bottom: 1px solid $main_color;\n}\n\n.szh-accordion__item-btn {\n  background-color: transparent;\n  border: none;\n  padding: 0.8rem;\n  text-align: left;\n  width: 100%;\n  font-size: 0.97rem;\n}\n\n.accordion-header {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.chevron {\n  margin-left: auto;\n  transition: transform 0.25s cubic-bezier(0, 0, 0, 1);\n}\n\n.itemBtnExpanded .chevron {\n  transform: rotate(180deg);\n}\n\n.szh-accordion__item-content {\n  padding: 0.5rem;\n  font-size: 0.8rem;\n  margin-bottom: 0.5rem;\n  line-height: 1.1rem;\n  opacity: 0.7;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
