// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html,
body {
  -webkit-tap-highlight-color: transparent;
}

body {
  background-color: rgb(235, 235, 235);
  overflow-x: hidden;
}

/* Prevent erros to show */
body > iframe {
  display: none;
}

.App {
  text-align: center;
  font-family: Inter, Verdana, Arial, Helvetica, sans-serif;
  max-width: 50rem;
  margin: auto;
  display: block;
}

a {
  text-decoration: none;
}

.sample-link {
  font-size: 1.2rem;
  margin: 0;
  margin-top: 1.7rem;
  font-weight: 100;
}

.discrete-p {
  font-size: 0.9rem;
  font-weight: lighter;
  opacity: 0.7;
  margin: 0;
}

.input-error {
  display: block;
  color: rgb(194, 6, 6);
  font-size: 0.75rem;
  margin-left: 0.5rem;
  margin-top: 0.3rem;
  transform: translateY(-0.1rem);
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/App.scss","webpack://./src/assets/styles/global-vars.scss"],"names":[],"mappings":"AAGA;;EAEE,wCAAA;AADF;;AAIA;EACE,oCCHO;EDIP,kBAAA;AADF;;AAIA,0BAAA;AACA;EACE,aAAA;AADF;;AAIA;EACE,kBAAA;EACA,yDAAA;EACA,gBAAA;EACA,YAAA;EACA,cAAA;AADF;;AAIA;EACE,qBAAA;AADF;;AAIA;EACE,iBAAA;EACA,SAAA;EACA,kBAAA;EACA,gBAAA;AADF;;AAIA;EACE,iBAAA;EACA,oBAAA;EACA,YAAA;EACA,SAAA;AADF;;AAIA;EACE,cAAA;EACA,qBAAA;EACA,kBAAA;EACA,mBAAA;EACA,kBAAA;EACA,8BAAA;AADF","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Inter&display=swap\");\n@import \"./global-vars.scss\";\n\nhtml,\nbody {\n  -webkit-tap-highlight-color: transparent;\n}\n\nbody {\n  background-color: $gray_2;\n  overflow-x: hidden;\n}\n\n/* Prevent erros to show */\nbody > iframe {\n  display: none;\n}\n\n.App {\n  text-align: center;\n  font-family: Inter, Verdana, Arial, Helvetica, sans-serif;\n  max-width: 50rem;\n  margin: auto;\n  display: block;\n}\n\na {\n  text-decoration: none;\n}\n\n.sample-link {\n  font-size: 1.2rem;\n  margin: 0;\n  margin-top: 1.7rem;\n  font-weight: 100;\n}\n\n.discrete-p {\n  font-size: 0.9rem;\n  font-weight: lighter;\n  opacity: 0.7;\n  margin: 0;\n}\n\n.input-error {\n  display: block;\n  color: rgb(194, 6, 6);\n  font-size: 0.75rem;\n  margin-left: 0.5rem;\n  margin-top: .3rem;\n  transform: translateY(-0.1rem);\n}\n","$main_color: #0A94D5;\n$secondary_color: #D3B112;\n\n$white: white;\n\n$gray_1: #5C5C5C;\n$gray_2: rgb(235, 235, 235);\n$gray_3: rgb(219, 219, 219);\n\n$section_v_padding: 0rem;\n$section_h_padding: 2.4rem;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
