// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Hero {
  box-sizing: border-box;
  padding: 0rem 2.4rem;
  position: relative;
  height: 100vh;
  height: 100svh;
}
.Hero__splash-wrapper {
  display: flex;
  justify-content: right;
  max-width: 30rem;
  margin: auto;
}
.Hero__splash {
  width: 100%;
  max-width: 20rem;
}
.Hero__title {
  font-weight: 100;
  font-size: 2.5rem;
  margin-top: 1.5rem;
  text-align: left;
  margin-bottom: 0.7rem;
}
.Hero__subtitle {
  opacity: 0.6;
  text-align: left;
  margin: 0;
  font-size: 1.1rem;
  font-weight: 200;
}
.Hero__img {
  margin-top: 3.2rem;
  margin-bottom: 1rem;
  height: 42vh;
  height: 42svh;
  width: auto;
  transform: scale(1.1) translateX(-2rem);
}

.hero-cta {
  position: absolute;
  bottom: 1.1rem;
  left: 50%;
  transform: translateX(-50%);
  width: 78%;
  z-index: 3;
}
.hero-cta__subtitle {
  font-size: 0.72rem;
  opacity: 0.7;
  margin-top: 0.4rem;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/Hero.scss"],"names":[],"mappings":"AAEA;EACE,sBAAA;EACA,oBAAA;EACA,kBAAA;EAEA,aAAA;EACA,cAAA;AAFF;AAIE;EACE,aAAA;EACA,sBAAA;EACA,gBAAA;EACA,YAAA;AAFJ;AAKE;EACE,WAAA;EACA,gBAAA;AAHJ;AAME;EACE,gBAAA;EACA,iBAAA;EACA,kBAAA;EACA,gBAAA;EACA,qBAAA;AAJJ;AAOE;EACE,YAAA;EACA,gBAAA;EACA,SAAA;EACA,iBAAA;EACA,gBAAA;AALJ;AAQE;EACE,kBAAA;EACA,mBAAA;EACA,YAAA;EACA,aAAA;EACA,WAAA;EACA,uCAAA;AANJ;;AAWA;EACE,kBAAA;EACA,cAAA;EACA,SAAA;EACA,2BAAA;EACA,UAAA;EACA,UAAA;AARF;AAUE;EACE,kBAAA;EACA,YAAA;EACA,kBAAA;AARJ","sourcesContent":["@import \"../styles/global-vars.scss\";\n\n.Hero {\n  box-sizing: border-box;\n  padding: $section_v_padding $section_h_padding;\n  position: relative;\n\n  height: 100vh;\n  height: 100svh;\n\n  &__splash-wrapper {\n    display: flex;\n    justify-content: right;\n    max-width: 30rem;\n    margin: auto;\n  }\n\n  &__splash {\n    width: 100%;\n    max-width: 20rem;\n  }\n\n  &__title {\n    font-weight: 100;\n    font-size: 2.50rem;\n    margin-top: 1.5rem;\n    text-align: left;\n    margin-bottom: .7rem;\n  }\n\n  &__subtitle {\n    opacity: 0.6;\n    text-align: left;\n    margin: 0;\n    font-size: 1.1rem;\n    font-weight: 200;\n  }\n\n  &__img {\n    margin-top: 3.2rem;\n    margin-bottom: 1rem;\n    height: 42vh;\n    height: 42svh;\n    width: auto;\n    transform: scale(1.1) translateX(-2rem);\n  }\n}\n\n\n.hero-cta {\n  position: absolute;\n  bottom: 1.1rem;\n  left: 50%;\n  transform: translateX(-50%);\n  width: 78%;\n  z-index: 3;\n\n  &__subtitle {\n    font-size: .72rem;\n    opacity: .7;\n    margin-top: .4rem;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
