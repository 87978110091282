// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ParallaxEl {
  --speed: 1;
  position: absolute;
  z-index: 5;
  animation: parallax-effect linear both;
  animation-timeline: scroll();
  animation-iteration-count: 1;
  height: 4rem;
}
.ParallaxEl__img {
  filter: drop-shadow(0 5px 20px rgba(0, 0, 0, 0.4));
  height: 100%;
  border-radius: 7px;
}

@keyframes parallax-effect {
  to {
    transform: translateY(calc(-100rem * var(--speed)));
  }
}
.parallax-trophy-wrapper-for-animation {
  transform: scale(0);
  animation: parallax-els-appear-animation 0.8s 1 forwards;
}
.parallax-trophy-wrapper-for-animation--2 {
  animation-duration: 1s;
}

@keyframes parallax-els-appear-animation {
  to {
    transform: none;
  }
}
.parallax-trophy-wrapper {
  height: 5rem;
  aspect-ratio: 1/1;
  border-radius: 50%;
  box-shadow: 0 5px 20px 1px rgba(0, 0, 0, 0.4);
  background-color: rgba(255, 255, 255, 0.7);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  align-items: center;
  animation-iteration-count: 1;
  z-index: 4;
  position: relative;
}
.parallax-trophy-wrapper__img {
  height: 70%;
}
.parallax-trophy-wrapper--2 {
  height: 7rem;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/ParallaxEl.scss"],"names":[],"mappings":"AAGA;EACE,UAAA;EACA,kBAAA;EACA,UAAA;EACA,sCAAA;EACA,4BAAA;EACA,4BAAA;EACA,YAAA;AAFF;AAIE;EACE,kDAAA;EACA,YAAA;EACA,kBAAA;AAFJ;;AAMA;EACE;IACE,mDAAA;EAHF;AACF;AAMA;EACE,mBAAA;EACA,wDAAA;AAJF;AAME;EACE,sBAAA;AAJJ;;AAQA;EACE;IACE,eAAA;EALF;AACF;AAQA;EACE,YAAA;EACA,iBAAA;EACA,kBAAA;EACA,6CAAA;EACA,0CAAA;EACA,mCAAA;UAAA,2BAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,4BAAA;EACA,UAAA;EACA,kBAAA;AANF;AAQE;EACE,WAAA;AANJ;AASE;EACE,YAAA;AAPJ","sourcesContent":["@import \"./global-vars.scss\";\n\n// Refactor. We must use many of those classes, but they mustn't be into this file\n.ParallaxEl {\n  --speed: 1;\n  position: absolute;\n  z-index: 5;\n  animation: parallax-effect linear both;\n  animation-timeline: scroll();\n  animation-iteration-count: 1;\n  height: 4rem;\n\n  &__img {\n    filter: drop-shadow(0 5px 20px rgba(0, 0, 0, 0.4));\n    height: 100%;\n    border-radius: 7px;\n  }\n}\n\n@keyframes parallax-effect {\n  to {\n    transform: translateY(calc(-100rem * var(--speed)));\n  }\n}\n\n.parallax-trophy-wrapper-for-animation {\n  transform: scale(0);\n  animation: parallax-els-appear-animation .8s 1 forwards;\n\n  &--2 {\n    animation-duration: 1s;\n  }\n}\n\n@keyframes parallax-els-appear-animation {\n  to {\n    transform: none;\n  }\n}\n\n.parallax-trophy-wrapper {\n  height: 5rem;\n  aspect-ratio: 1 / 1;\n  border-radius: 50%;\n  box-shadow: 0 5px 20px 1px rgba(0, 0, 0, 0.4);\n  background-color: rgba(255, 255, 255, 0.7);\n  backdrop-filter: blur(10px);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  animation-iteration-count: 1;\n  z-index: 4;\n  position: relative;\n\n  &__img {\n    height: 70%;\n  }\n\n  &--2 {\n    height: 7rem;\n\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
