// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FeaturesSection__feature {
  margin-bottom: 2rem;
}
.FeaturesSection__subtitle {
  margin: 0;
  text-align: left;
  font-size: 2rem;
  font-weight: 100;
}
.FeaturesSection__p {
  margin: 0;
  margin-top: 0.5rem;
  text-align: left;
  opacity: 0.5;
  font-size: 0.9rem;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/FeaturesSection.scss"],"names":[],"mappings":"AACE;EACE,mBAAA;AAAJ;AAGE;EACE,SAAA;EACA,gBAAA;EACA,eAAA;EACA,gBAAA;AADJ;AAIE;EACE,SAAA;EACA,kBAAA;EACA,gBAAA;EACA,YAAA;EACA,iBAAA;AAFJ","sourcesContent":[".FeaturesSection {\n  &__feature {\n    margin-bottom: 2rem;;\n  }\n  \n  &__subtitle {\n    margin: 0;\n    text-align: left;\n    font-size: 2rem;\n    font-weight: 100;\n  }\n\n  &__p {\n    margin: 0;\n    margin-top: .5rem;\n    text-align: left;\n    opacity: .5;\n    font-size: .9rem;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
