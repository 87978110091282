// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VideoSection__p {
  font-size: 1.1rem;
  text-align: left;
  opacity: 0.8;
  margin: 0;
}
.VideoSection__video-wrapper {
  display: block;
  margin: auto;
  border-radius: 6px;
  overflow: hidden;
  margin-top: 2rem;
  width: 60%;
  aspect-ratio: 9/19.46;
  transition: all 0.3s;
}
.VideoSection__video-wrapper--showing {
  transition: all 0.3s;
  transform: scale(1.5) translateY(-20%);
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/VideoSection.scss"],"names":[],"mappings":"AACE;EACE,iBAAA;EACA,gBAAA;EACA,YAAA;EACA,SAAA;AAAJ;AAGE;EACE,cAAA;EACA,YAAA;EACA,kBAAA;EACF,gBAAA;EACE,gBAAA;EACA,UAAA;EACA,qBAAA;EACA,oBAAA;AADJ;AAGI;EACE,oBAAA;EACA,sCAAA;AADN","sourcesContent":[".VideoSection {\n  &__p {\n    font-size: 1.1rem;\n    text-align: left;\n    opacity: 0.8;\n    margin: 0;\n  }\n\n  &__video-wrapper {\n    display: block;\n    margin: auto;\n    border-radius: 6px;\n  overflow: hidden;\n    margin-top: 2rem;\n    width: 60%;\n    aspect-ratio: 9/19.46;\n    transition: all 0.3s;\n    \n    &--showing {\n      transition: all 0.3s;\n      transform: scale(1.5) translateY(-20%);\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
