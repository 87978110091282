import React from "react";
import CtaButton from "./CtaButton";

import "../styles/Hero.scss";
import mockup from "../imgs/mockup.png";
import ParallaxEl from "./ParallaxEl";

import trophy1 from "../imgs/trophy-1.png";
import trophy2 from "../imgs/trophy-2.png";

export default function Hero() {
  return (
    <section className="Hero">
      <h2 className="Hero__title">
        Todos tus <br /> logros en el <br /> bolsillo.
      </h2>

      <h3 className="Hero__subtitle">
        Disfruta en tu móvil de
        <br />
        todos tus trofeos y sigue
        <br />
        tu progresión hacia el éxito
      </h3>

      <div className="hero-cta">
        <CtaButton primary={true} count="1" data-umami-event="create-account-button" data-umami-event-num="1">
          Crea tu cuenta gratis
        </CtaButton>
        <p className="hero-cta__subtitle">
          Únete a la comunidad Mitali
        </p>
      </div>

      <div className="Hero__splash-wrapper">
        <div className="Hero__splash">
          <ParallaxEl speed={-10}>
            <img
              height="700"
              width="400"
              fetchpriority="high"
              className="Hero__img"
              src={mockup}
              alt="Captura de pantalla de la app"
            />
          </ParallaxEl>
        </div>
      </div>
    </section>
  );
}
